import useTranslation from 'next-translate/useTranslation';
import { differenceInCalendarDays, isSameMonth } from 'date-fns';
import { EventSchedule as Schedule } from '@afishauz/api/event-schedules';
import { useDateTranslator } from '../utils/date';

interface Props {
  className?: string;
  schedules: Schedule[];
  activeDate?: Date;
}

export const EventSchedule = ({ className, schedules, activeDate }: Props) => {
  const { t } = useTranslation();
  const { format } = useDateTranslator();

  if (schedules.length === 1) {
    const [schedule] = schedules;
    const startDate = new Date(schedule.startedAt);
    if (schedule.endedAt) {
      const endDate = new Date(schedule.endedAt);
      return (
        <span className={className}>
          {t('elements.event_schedule', {
            start: format(
              startDate,
              isSameMonth(startDate, endDate) ? 'd' : 'd MMMM',
            ),
            end: format(endDate, 'd MMMM'),
          })}
        </span>
      );
    }
    return (
      <span className={className}>
        {format(activeDate ?? startDate, 'd MMMM')}
      </span>
    );
  }

  const [schedule] = [...schedules].filter(schedule => {
    if (schedule.isExactStartTime) {
      return new Date(schedule.startedAt) >= new Date();
    }
    return (
      differenceInCalendarDays(new Date(schedule.startedAt), new Date()) >= 0
    );
  });

  if (!schedule) {
    const lastSchedule = schedules[schedules.length - 1];
    const startDate = new Date(lastSchedule.startedAt);
    if (lastSchedule.endedAt) {
      const endDate = new Date(lastSchedule.endedAt);
      return (
        <span className={className}>
          {t('elements.event_schedule', {
            start: format(
              startDate,
              isSameMonth(startDate, endDate) ? 'd' : 'd MMMM',
            ),
            end: format(endDate, 'd MMMM'),
          })}
        </span>
      );
    }
    return (
      <span className={className}>
        {format(activeDate ?? startDate, 'd MMMM')}
      </span>
    );
  }

  return (
    <span className={className}>
      {format(activeDate ?? new Date(schedule.startedAt), 'd MMMM')}
    </span>
  );
};
