import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faTelegram } from '@fortawesome/free-brands-svg-icons/faTelegram';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuItem } from '@afishauz/api/menu-items';
import { parseLocale, SOCIAL_MEDIA } from '@afishauz/core/config';
import { PushSubscribeButton } from '@afishauz/core/widgets/push-subscribe-button';
import { faRss } from '@fortawesome/free-solid-svg-icons/faRss';
import { Container } from '@afishauz/ui-kit/container';
import { Link } from '@afishauz/core/widgets/link';
import { Afishauz } from '@afishauz/icons';

interface Props {
  menuItems: MenuItem[];
}

const YEAR = new Date().getFullYear();

export const Footer = ({ menuItems = [] }: Props) => {
  const { lang } = useTranslation();
  const locale = parseLocale(lang);

  if (menuItems.length === 0) {
    return null;
  }

  return (
    <footer className='py-12 mt-8'>
      <Container>
        <div className='flex items-end justify-between mb-8'>
          <div>
            <Link href='/' aria-label='logo'>
              <Afishauz width={172} className='text-afisha' />
            </Link>
          </div>
          <nav className='ml-20 mr-auto'>
            <ul className='flex gap-x-7 items-center'>
              {menuItems.map(item => (
                <li key={item['@id']}>
                  <Link
                    href={item.permalink}
                    className='transition-colors hover:text-afisha'
                    target={item.target}>
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
          <ul className='flex gap-x-4 items-center text-gray-400'>
            <li className='mr-4'>
              <PushSubscribeButton />
            </li>
            <li>
              <a
                className='text-xl transition-colors hover:text-sky-400'
                href={SOCIAL_MEDIA[locale].TELEGRAM}
                target='_blank'
                rel='noreferrer'
                aria-label='telegram'>
                <FontAwesomeIcon icon={faTelegram} />
              </a>
            </li>
            <li>
              <a
                className='text-xl transition-colors hover:text-blue-800'
                href={SOCIAL_MEDIA[locale].FACEBOOK}
                target='_blank'
                rel='noreferrer'
                aria-label='facebook'>
                <FontAwesomeIcon icon={faFacebookSquare} />
              </a>
            </li>
            <li>
              <a
                className='text-xl transition-colors hover:text-fuchsia-700'
                href={SOCIAL_MEDIA[locale].INSTAGRAM}
                target='_blank'
                rel='noreferrer'
                aria-label='instagram'>
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </li>
            <li>
              <a
                className='text-xl transition-colors hover:text-sky-500'
                href={SOCIAL_MEDIA[locale].TWITTER}
                target='_blank'
                rel='noreferrer'
                aria-label='twitter'>
                <FontAwesomeIcon icon={faTwitter} />
              </a>
            </li>
            <li>
              <a
                className='text-xl transition-colors hover:text-red-600'
                href={SOCIAL_MEDIA[locale].YOUTUBE}
                target='_blank'
                rel='noreferrer'
                aria-label='youtube'>
                <FontAwesomeIcon icon={faYoutube} />
              </a>
            </li>
            <li>
              <Link
                href='/feeds'
                className='text-xl transition-colors hover:text-amber-500'
                aria-label='rss'>
                <FontAwesomeIcon icon={faRss} />
              </Link>
            </li>
          </ul>
        </div>
        <div className='ml-[252px] max-w-[600px] text-gray-400'>
          <div className='text-sm mb-6'>
            <Trans
              i18nKey='layouts.desktop_footer.terms'
              components={{ 1: <Link href='/terms' className='link' /> }}
              ns='common'
            />
          </div>
          <div className='text-sm mb-6'>
            <Trans
              i18nKey='layouts.desktop_footer.certificate'
              components={{
                0: <br />,
                1: <a href='mailto:info@afisha.uz' className='link' />,
              }}
              ns='common'
            />
          </div>
          <div className='text-sm'>
            <Trans
              i18nKey='layouts.desktop_footer.copyright'
              values={{ year: YEAR }}
              ns='common'
              components={{ 1: <br /> }}
            />
          </div>
        </div>
      </Container>
    </footer>
  );
};
