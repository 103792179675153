import { isDefined } from './helpers';

type PrimitiveValue = string | number | boolean | null | undefined;
type Params = Record<string, PrimitiveValue | PrimitiveValue[]>;

export function createSearchParams(params: Params) {
  const searchParams = new URLSearchParams();
  Object.entries(params).forEach(([name, value]) => {
    if (isDefined(value)) {
      if (Array.isArray(value)) {
        value.forEach(val => {
          if (isDefined(val)) {
            searchParams.append(name, val.toString());
          }
        });
      } else {
        searchParams.append(name, value.toString());
      }
    }
  });
  return searchParams;
}
