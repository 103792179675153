import { Options } from 'ky';
import { Locale } from '../config';
import { httpClient } from '../http-client';
import { createSearchParams } from '@afishauz/lib/url';
import { User } from './types';

export type GetMeParams = {
  locale?: Locale;
};

export async function getMe(
  params: GetMeParams = {},
  options: Omit<Options, 'searchParams' | 'body' | 'json'> = {},
) {
  const response = await httpClient.get('api/users/me', {
    ...options,
    searchParams: createSearchParams(params),
  });
  return await response.json<User>();
}

export type UpdateUserPayload = Partial<User>;

export async function updateUser(
  id: number,
  payload: UpdateUserPayload,
  options: Omit<Options, 'searchParams' | 'json' | 'body'> = {},
) {
  const response = await httpClient.put(`api/users/${id}`, {
    ...options,
    json: payload,
  });
  return await response.json<User>();
}
