import { RefObject, useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { User } from '@afishauz/api/users';
import { parseLocale, SOCIAL_MEDIA } from '@afishauz/core/config';
import { Avatar } from '@afishauz/ui-kit/avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons/faArrowUpRightFromSquare';
import { faGear } from '@fortawesome/free-solid-svg-icons/faGear';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons/faLocationDot';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons/faRightFromBracket';
import { Settings } from '@/layouts/header/settings';
import { twMerge } from 'tailwind-merge';

interface Props {
  user: User;
  trigger: RefObject<HTMLElement>;
}

export const UserDropdown = ({ user, trigger }: Props) => {
  const [opened, setOpened] = useState(false);
  const [settingsOpened, setSettingsOpened] = useState(false);
  const { t, lang } = useTranslation();
  const locale = parseLocale(lang);

  useEffect(() => {
    const handleTriggerClick = () => {
      setOpened(true);
    };
    const refValue = trigger.current;
    if (refValue) {
      refValue.addEventListener('click', handleTriggerClick);
    }
    return () => {
      if (refValue) {
        refValue.removeEventListener('click', handleTriggerClick);
      }
    };
  }, [trigger]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        trigger.current &&
        !trigger.current.contains(event.target as HTMLElement)
      ) {
        setOpened(false);
      }
    };
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [trigger]);

  if (!user) {
    return null;
  }

  return (
    <div
      className='relative'
      onClick={event => {
        event.stopPropagation();
      }}>
      <div
        className={twMerge(
          'absolute -top-12 right-0 w-72 bg-white rounded-md z-50 shadow-afisha-md overflow-hidden hidden animate-fade-in',
          opened && 'block',
        )}>
        <Settings
          isOpen={settingsOpened}
          onClose={() => {
            setSettingsOpened(false);
          }}
        />
        {!settingsOpened && (
          <>
            <div className='flex gap-x-4 items-center border-b p-4'>
              <Avatar src={user.avatarUrl} alt={user.fullName} />
              <div>
                <div className='font-medium text-sm'>{user.fullName}</div>
                <div className='mt-1 text-xs text-gray-400'>
                  {t('layouts.user_dropdown.manage')}
                </div>
              </div>
            </div>
            <ul>
              <li className='px-4 py-3 flex items-center gap-x-3.5 font-medium text-sm select-none'>
                <FontAwesomeIcon
                  icon={faLocationDot}
                  aria-label='city'
                  className='text-lg text-gray-400'
                />
                {t('layouts.user_dropdown.city')}
              </li>
              <li
                className='px-4 py-3 flex items-center gap-x-3.5 font-medium text-sm select-none cursor-pointer hover:bg-gray-100 transition-colors'
                onClick={() => {
                  setSettingsOpened(true);
                }}>
                <FontAwesomeIcon
                  icon={faGear}
                  aria-label='settings'
                  className='text-lg text-gray-400'
                />
                {t('layouts.user_dropdown.settings.title')}
              </li>
              <li>
                <a
                  href='/logout'
                  className='px-4 py-3 flex items-center gap-x-3.5 font-medium text-sm select-none cursor-pointer hover:bg-gray-100'>
                  <FontAwesomeIcon
                    icon={faRightFromBracket}
                    aria-label='logout'
                    className='text-lg text-gray-400'
                  />
                  {t('layouts.user_dropdown.log_out')}
                </a>
              </li>
            </ul>
            <div className='pt-3.5 pb-4 pl-4 bg-[url("/assets/images/dropdown-img.png")] bg-no-repeat bg-right-bottom bg-contain border-t'>
              <div className='max-w-[123px] font-semibold text-xl'>
                {t('layouts.user_dropdown.subscribe')}
              </div>
              <a
                href={SOCIAL_MEDIA[locale].TELEGRAM}
                target='_blank'
                rel='noreferrer'
                className='inline-block mt-3 px-4 py-2.5 bg-cyan-500 rounded-md text-white hover:bg-cyan-600 transition-colors'>
                <span className='flex items-center gap-x-1.5'>
                  {t('layouts.user_dropdown.go')}
                  <FontAwesomeIcon
                    icon={faArrowUpRightFromSquare}
                    aria-label='subscribe to telegram channel'
                  />
                </span>
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
