
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { useEffect, useState } from 'react';
import Script from 'next/script';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Inter } from 'next/font/google';
import '@afishauz/assets/styles/global.css';
import appWithI18n from 'next-translate/appWithI18n';
import { LoaderConfig } from 'next-translate';
import useTranslation from 'next-translate/useTranslation';
import i18nConfig from '../i18n';
import { SWRConfig } from 'swr';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { config } from '@fortawesome/fontawesome-svg-core';
import { Toaster } from 'react-hot-toast';
import { API_ORIGIN } from '@afishauz/api/config';
import { getMe, User } from '@afishauz/api/users';
import { UserProvider } from '@afishauz/core/utils/user';
import { parseLocale } from '@afishauz/core/config';
import { useScrollRestoration } from '@afishauz/core/utils/scroll-restoration';
import { useThemes } from '@afishauz/core/utils/themes';
import { useNprogress } from '@afishauz/core/utils/nprogress';
import { useYmHit, useGtmDataLayer } from '@afishauz/core/utils/analytics';
import { PushNotificationsProvider } from '@afishauz/core/utils/fcm';
import { Overlay } from '@afishauz/ui-kit/overlay';
import { FloatingAdBanner } from '@afishauz/core/widgets/floating-ad-banner';
import { PushNotifDialog } from '@/templates/shared/push-notif-dialog';
import '@afishauz/assets/styles/nprogress.css';
import { AppLayout } from '@/layouts/app-layout';
import { ErrorBoundary } from '@afishauz/core/widgets/error-boundary';
import { createGetInitialProps, AppProps } from '@afishauz/core/pages/_app';
config.autoAddCss = false;
const fontFamily = Inter({
    subsets: ['latin', 'cyrillic'],
    weight: ['400', '500', '600']
});
function App({ Component, pageProps, menus, themes }: AppProps) {
    const [user, setUser] = useState<User | null>(null);
    const router = useRouter();
    const { lang } = useTranslation();
    const locale = parseLocale(lang);
    useNprogress();
    useScrollRestoration('auto');
    useGtmDataLayer();
    useYmHit(`/${locale}${router.asPath}`);
    useThemes(themes, 'desktop');
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const fetchUser = async () => {
            try {
                const data = await getMe({ locale });
                if (data) {
                    setUser(data);
                }
            }
            catch {
                setUser(null);
            }
        };
        fetchUser();
    }, [locale]);
    useEffect(() => {
        const handleRouteChangeStart = () => {
            setLoading(true);
        };
        const handleRouteChangeComplete = () => {
            setLoading(false);
        };
        const handleRouterChangeError = () => {
            setLoading(false);
        };
        router.events.on('routeChangeStart', handleRouteChangeStart);
        router.events.on('routeChangeComplete', handleRouteChangeComplete);
        router.events.on('routeChangeError', handleRouterChangeError);
        return () => {
            router.events.off('routeChangeStart', handleRouteChangeStart);
            router.events.off('routeChangeComplete', handleRouteChangeComplete);
            router.events.off('routeChangeError', handleRouterChangeError);
        };
    }, [router.events]);
    const { asPath } = router;
    const endIndex = asPath
        .split('')
        .findIndex(char => char === '?' || char === '#');
    const path = asPath.slice(0, endIndex > 0 ? endIndex : asPath.length);
    const canonicalUrl = new URL(`${locale}${path === '/' ? '' : path}`, API_ORIGIN).toString();
    return (<>
      <Head>
        <meta property='og:url' content={canonicalUrl}/>
        <link rel='canonical' href={canonicalUrl}/>
      </Head>
      <UserProvider value={{ user, updateUser: setUser }}>
        <ErrorBoundary>
          <SWRConfig value={{ revalidateOnFocus: false }}>
            <PushNotificationsProvider>
              <div className={fontFamily.className}>
                <PushNotifDialog />
                <AppLayout menus={menus}>
                  <Component {...pageProps}/>
                </AppLayout>
                <FloatingAdBanner />
                <Overlay show={loading} theme='transparent'/>
                <Toaster position='bottom-right' toastOptions={{ duration: 5000 }}/>
              </div>
            </PushNotificationsProvider>
          </SWRConfig>
        </ErrorBoundary>
      </UserProvider>
      <Script id='gtag-base' strategy='afterInteractive' dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', 'GTM-TQ6H8PK');
          `
        }}/>
      <Script id='ym-init' dangerouslySetInnerHTML={{
            __html: `
             (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
             m[i].l=1*new Date();
             for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
             k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
             (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
             ym(9561514, "init", {
                defer: true,
                clickmap:true,
                trackLinks:true,
                accurateTrackBounce:true,
             });
          `
        }}/>
      <noscript>
        <div>
          <img src='https://mc.yandex.ru/watch/9561514' style={{ position: 'absolute', left: -9999 }} alt=''/>
        </div>
      </noscript>
    </>);
}
App.getInitialProps = createGetInitialProps();
const __Next_Translate__Page__1917f1d127f__ = appWithI18n(App as any, i18nConfig as LoaderConfig);

    export default __appWithI18n(__Next_Translate__Page__1917f1d127f__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
    });
  