import { useRouter } from 'next/router';
import { MenuItem } from '@afishauz/api/menu-items';
import { Link } from '@afishauz/core/widgets/link';
import { Button } from '@afishauz/ui-kit/button';
import useTranslation from 'next-translate/useTranslation';
import { parseLocale, AD_ZONES } from '@afishauz/core/config';
import { useAdvertisement } from '@afishauz/core/utils/ads';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@afishauz/ui-kit/dropdown-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const NavigationAdButton = () => {
  const { lang } = useTranslation();
  const locale = parseLocale(lang);
  const { data, ref } = useAdvertisement({
    zoneId: AD_ZONES[locale].NAVIGATION_AD,
  });

  if (!data) {
    return null;
  }

  return (
    <span ref={ref}>
      <a href={data.clickUrl} target='_blank' rel='noreferrer'>
        <img src={data.creativeUrl} height={36} alt='ad' />
      </a>
    </span>
  );
};

interface Props {
  menuItems: MenuItem[];
}

export const MainNavigation = ({ menuItems = [] }: Props) => {
  const router = useRouter();

  if (menuItems.length === 0) {
    return null;
  }

  const elements = menuItems.map(item => (
    <li key={item['@id']} className='grow last:grow-0'>
      {item.childMenuItems && item.childMenuItems.length > 0 ? (
        <DropdownMenu modal={false}>
          <DropdownMenuTrigger asChild>
            <Button
              size='sm'
              rightSection={<FontAwesomeIcon icon={faChevronDown} size='xs' />}>
              {item.name}
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align='end'>
            {item.childMenuItems.map(child => (
              <DropdownMenuItem key={child['@id']} asChild>
                <Link href={child.permalink} className='block'>
                  {child.name}
                </Link>
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      ) : (
        <Button
          component={Link}
          color={
            router.asPath.includes(
              item.permalink === '#' ? '/catalog' : item.permalink,
            )
              ? 'primary'
              : 'default'
          }
          size='sm'
          key={item['@id']}
          href={item.permalink === '#' ? '/catalog' : item.permalink}
          leftSection={
            item.iconType === 'SVG' &&
            !!item.icon && (
              <span dangerouslySetInnerHTML={{ __html: item.icon }} />
            )
          }>
          {item.name}
        </Button>
      )}
    </li>
  ));

  elements.splice(6, 0, <NavigationAdButton key='navigation-ad' />);

  return (
    <nav>
      <ul className='flex gap-x-2 grow last:grow-0 text-sm'>{elements}</ul>
    </nav>
  );
};
