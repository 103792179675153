import { GetCollectionOptions } from '../collection';
import { GetPlacesParams, SearchPlaceParams } from './service';
import { Place } from './types';
import {
  UseCollectionOptions,
  SWROptions,
  useCollection,
} from '../use-collection';

export function usePlaces(
  options: UseCollectionOptions<Place, Omit<GetPlacesParams, 'page'>> = {},
  requestOptions: GetCollectionOptions = {},
  swrConfig: SWROptions = {},
) {
  return useCollection<Place>('api/places', options, requestOptions, swrConfig);
}

export function usePlacesSearch(
  options: UseCollectionOptions<Place, Omit<SearchPlaceParams, 'page'>> = {},
  requestOptions: GetCollectionOptions = {},
  swrConfig: SWROptions = {},
) {
  return useCollection<Place>(
    'api/places/search',
    options,
    requestOptions,
    swrConfig,
  );
}
