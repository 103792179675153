import { forwardRef, PropsWithChildren, useEffect, useState } from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

const avatar = cva(
  [
    'bg-gray-300',
    'overflow-hidden',
    'inline-flex',
    'justify-center',
    'items-center',
  ],
  {
    variants: {
      size: {
        sm: ['w-8', 'h-8', 'min-w-[32px]', 'min-h-[32px]', 'text-sm'],
        md: ['w-9', 'h-9', 'min-w-[36px]', 'min-h-[36px]'],
        lg: ['w-10', 'h-10', 'min-w-[40px]', 'min-h-[40px]'],
      },
      radius: {
        none: ['rounded-none'],
        sm: ['rounded-sm'],
        md: ['rounded-md'],
        lg: ['rounded-lg'],
        full: ['rounded-full'],
      },
    },
    defaultVariants: {
      size: 'md',
      radius: 'full',
    },
  },
);

export interface AvatarProps
  extends VariantProps<typeof avatar>,
    PropsWithChildren {
  src?: string | null;
  alt?: string;
  className?: string;
}

export const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
  ({ src, alt, className, size, radius, children }, ref) => {
    const [error, setError] = useState(false);

    useEffect(() => {
      setError(false);
    }, [src]);

    return (
      <div ref={ref} className={twMerge(avatar({ size, radius }), className)}>
        {src && !error ? (
          <img
            src={src}
            alt={alt}
            loading='lazy'
            className='w-full h-full object-cover'
            onError={() => {
              setError(true);
            }}
          />
        ) : (
          children
        )}
      </div>
    );
  },
);

Avatar.displayName = 'Avatar';
