import useTranslation from 'next-translate/useTranslation';
import { parseLocale, LOCALE } from '@afishauz/core/config';
import { useCurrentWeatherForecasts } from '@afishauz/api/weather-forecasts';
import { Sun } from '@afishauz/icons';

export const WeatherForecast = () => {
  const { lang } = useTranslation();
  const { list, isLoadingFirstPage } = useCurrentWeatherForecasts({
    keyParams: {
      'city[slug]': 'tashkent',
    },
  });

  if (list.filter(Boolean).length === 0) {
    return null;
  }

  const locale = parseLocale(lang);
  const link =
    locale === LOCALE.uz ? 'https://obhavo.uz/' : 'https://pogoda.uz';

  const daily = list.find(item => item.type === 'DAILY');
  const hourly = list.find(item => item.type === 'HOURLY');

  if (isLoadingFirstPage) {
    return null;
  }

  return (
    <a
      href={link}
      target='_blank'
      rel='noreferrer'
      className='flex items-center gap-x-3 pr-3'
      aria-label='weather forecast'>
      <Sun width={20} className='text-[#F3ba08]' />
      <div>
        {!!hourly && <div className='text-sm'>{hourly.lowTemperature}°C</div>}
        {!!daily && (
          <div className='text-xs'>
            {daily.highTemperature}°&nbsp;
            <span className='text-gray-400'>{daily.lowTemperature}°</span>
          </div>
        )}
      </div>
    </a>
  );
};
