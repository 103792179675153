import { useEffect } from 'react';
import { useRouter } from 'next/router';
import NProgress, { NProgressOptions } from 'nprogress';

export const useNprogress = (options: Partial<NProgressOptions> = {}) => {
  const { events } = useRouter();

  useEffect(() => {
    NProgress.configure(options);
    const handleRouteStart = () => {
      NProgress.start();
    };
    const handleRouteChangeComplete = () => {
      NProgress.done();
    };
    events.on('routeChangeStart', handleRouteStart);
    events.on('routeChangeComplete', handleRouteChangeComplete);
    events.on('routeChangeError', handleRouteChangeComplete);
    return () => {
      events.off('routeChangeStart', handleRouteStart);
      events.off('routeChangeComplete', handleRouteChangeComplete);
      events.off('routeChangeError', handleRouteChangeComplete);
    };
  }, [events, options]);
};
