import useTranslation from 'next-translate/useTranslation';
import { isSameYear } from 'date-fns';
import { useDateTranslator } from '../utils/date';

interface Props {
  date: Date;
}

export const PublishedAt = ({ date }: Props) => {
  const { t } = useTranslation();
  const { format } = useDateTranslator();

  return (
    <>
      {t('elements.published_at', {
        date: format(
          date,
          isSameYear(new Date(date), new Date()) ? 'd MMMM' : 'd MMMM yyyy',
        ),
      })}
    </>
  );
};
