import { AnchorHTMLAttributes, forwardRef } from 'react';
import NextLink, { type LinkProps as NextLinkProps } from 'next/link';

export type LinkProps = NextLinkProps &
  AnchorHTMLAttributes<HTMLAnchorElement> & { nativeLink?: boolean };

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ prefetch, nativeLink = false, ...rest }, ref) => {
    return nativeLink ? (
      <a {...rest} />
    ) : (
      <NextLink {...rest} prefetch={prefetch ?? false} ref={ref} />
    );
  },
);

Link.displayName = 'Link';
