import { Options } from 'ky';
import { httpClient } from './http-client';
import { logger } from '@afishauz/lib/logger';
import { createSearchParams } from '@afishauz/lib/url';

export type Collection<T> = {
  'hydra:member': T[];
  'hydra:totalItems': number;
};

export function emptyCollection<T>() {
  return {
    'hydra:member': [] as T[],
    'hydra:totalItems': 0,
  };
}

export type GetCollectionOptions = Omit<
  Options,
  'searchParams' | 'body' | 'json' | 'method'
>;

export type GetCollectionParams = Record<
  string,
  | string
  | number
  | boolean
  | null
  | undefined
  | Array<string | number | boolean | null | undefined>
>;

export interface PaginationParams extends GetCollectionParams {
  page?: number;
  itemsPerPage?: number;
}

export async function getCollection<T>(
  endpoint: string,
  params: GetCollectionParams = {},
  options: GetCollectionOptions = {},
) {
  try {
    const response = await httpClient.get(endpoint, {
      ...options,
      searchParams: createSearchParams(params),
    });
    return await response.json<Collection<T>>();
  } catch (error) {
    logger.error(error);
    return emptyCollection<T>();
  }
}
