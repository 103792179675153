import { Translate } from 'next-translate';
import { API_ORIGIN, DEFAULT_PREVIEW, Locale } from '@afishauz/api/config';
import { useMaterialsSearch } from '@afishauz/api/materials';
import { usePerformancesSearch } from '@afishauz/api/performances';
import { usePlacesSearch } from '@afishauz/api/places';
import { useVideosSearch } from '@afishauz/api/videos';
import { isShortSearchQuery } from '../utils/search';

export const generateMetadata = (t: Translate) => {
  const title = t('meta.title');
  const description = t('meta.description');
  return {
    title,
    description,
    ogImage: new URL(DEFAULT_PREVIEW, API_ORIGIN).toString(),
    alternate: [
      {
        hrefLang: 'ru',
        href: 'https://www.afisha.uz/ru/search/',
      },
      {
        hrefLang: 'uz-Latn',
        href: 'https://www.afisha.uz/uz/search',
      },
    ],
  };
};

export function useSearchMaterials({
  q,
  locale,
  fuzzy,
}: {
  q: string;
  locale: Locale;
  fuzzy?: boolean;
}) {
  return useMaterialsSearch({
    keyParams: {
      q,
      locale,
      fuzzy: fuzzy ? 1 : undefined,
      'orderBy[eventScheduleDate]': 'DESC',
      'type[]': ['ARTICLE', 'NEWS', 'PHOTOSET', 'VIDEO'],
      'status[]': ['PUBLISHED'],
      'groups[]': ['material:permalink_canonical'],
      itemsPerPage: 8,
    },
    postpone: isShortSearchQuery(q),
  });
}

export function useSearchEvents({
  q,
  locale,
  fuzzy,
}: {
  q: string;
  locale: Locale;
  fuzzy?: boolean;
}) {
  return useMaterialsSearch({
    keyParams: {
      q,
      locale,
      fuzzy: fuzzy ? 1 : undefined,
      'orderBy[eventScheduleDate]': 'DESC',
      'type[]': ['EVENT'],
      'status[]': ['PUBLISHED'],
      'groups[]': ['material:event_schedules', 'material:permalink_canonical'],
      itemsPerPage: 8,
    },
    postpone: isShortSearchQuery(q),
  });
}

export function useSearchPerformances({
  q,
  locale,
  fuzzy,
}: {
  q: string;
  locale: Locale;
  fuzzy?: boolean;
}) {
  return usePerformancesSearch({
    keyParams: {
      q,
      locale,
      fuzzy: fuzzy ? 1 : undefined,
      itemsPerPage: 8,
    },
    postpone: isShortSearchQuery(q),
  });
}

export function useSearchPlaces({
  q,
  fuzzy,
  locale,
}: {
  q: string;
  locale: Locale;
  fuzzy?: boolean;
}) {
  return usePlacesSearch({
    keyParams: {
      q,
      locale,
      fuzzy: fuzzy ? 1 : undefined,
      itemsPerPage: 8,
    },
    postpone: isShortSearchQuery(q),
  });
}

export function useSearchVideos({
  q,
  locale,
  fuzzy,
}: {
  q: string;
  locale: Locale;
  fuzzy?: boolean;
}) {
  return useVideosSearch({
    keyParams: {
      q,
      locale,
      fuzzy: fuzzy ? 1 : undefined,
      itemsPerPage: 8,
    },
    postpone: isShortSearchQuery(q),
  });
}
