import { useEffect } from 'react';
import { useRouter } from 'next/router';

export function useGtmDataLayer() {
  const { locale } = useRouter();

  useEffect(() => {
    (window as any).dataLayer?.push({
      lang: locale,
    });
  }, [locale]);
}

export function useYmHit(permalink: string | null) {
  useEffect(() => {
    if (permalink) {
      (window as any).ym?.('9561514', 'hit', permalink);
    }
  }, [permalink]);
}
