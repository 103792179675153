import { Options } from 'ky';
import { logger } from '@afishauz/lib/logger';
import { Locale } from '../config';
import { PushSubscriber, PushSubscriberPlatform } from './types';
import { httpClient } from '../http-client';
import { createSearchParams } from '@afishauz/lib/url';
import { Collection } from '../collection';

export interface CreatePushSubscriberPayload {
  platform: PushSubscriberPlatform;
  token: string;
  locale: Locale;
}

export async function createPushSubscriber(
  payload: CreatePushSubscriberPayload,
  options: Omit<Options, 'method' | 'body' | 'json'> = {},
) {
  try {
    const response = await httpClient.post('api/push_subscribers', {
      ...options,
      json: payload,
    });
    return await response.json<PushSubscriber>();
  } catch (error) {
    logger.error(error);
    return null;
  }
}

export interface DeactivatePushSubscriberParams {
  platform: PushSubscriberPlatform;
  token: string;
}

export async function deactivatePushSubscriber(
  { platform, token }: DeactivatePushSubscriberParams,
  options: Omit<Options, 'body' | 'json' | 'method' | 'searchParams'> = {},
) {
  try {
    await httpClient.delete('api/push_subscribers/deactivate', {
      ...options,
      searchParams: createSearchParams({ platform, token }),
    });
  } catch (error) {
    logger.error(error);
  }
}

export interface CheckPushSubscriberExistParams {
  platform: PushSubscriberPlatform;
  tokenHash: string;
  locale: Locale;
}

export async function checkPushSubscriberExist(
  { platform, tokenHash, locale }: CheckPushSubscriberExistParams,
  options: Omit<Options, 'method' | 'searchParams' | 'body' | 'json'> = {},
) {
  try {
    const response = await httpClient.get('api/push_subscribers/exist', {
      ...options,
      searchParams: createSearchParams({ platform, tokenHash, locale }),
    });
    const collection = await response.json<Collection<{ exists: boolean }>>();
    return collection['hydra:member'].at(0)?.exists ?? false;
  } catch (error) {
    logger.error(error);
    return false;
  }
}
