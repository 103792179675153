import { useEffect, useRef } from 'react';
import useSWRImmutable from 'swr/immutable';

const AD_DELIVERY_URL = 'https://oa.afishamedia.net/www/delivery/ax.php';
const LOG_URL_KEY = 'logUrl';
const CLICK_URL_KEY = 'clickUrl';
const CREATIVE_URL_KEY = 'creativeUrl';
const APPEND_KEY = 'append';
const PREPEND_KEY = 'prepend';
const WIDTH_KEY = 'width';
const HEIGHT_KEY = 'height';

export function useAdvertisement({
  zoneId,
  source,
}: {
  zoneId: number;
  source?: string;
}) {
  const {
    data: xml,
    isLoading,
    mutate: update,
  } = useSWRImmutable(
    [AD_DELIVERY_URL, zoneId, source],
    async ([url]) => {
      const searchParams = new URLSearchParams();
      searchParams.set('zones', String(zoneId));
      if (source) {
        searchParams.set('source', source);
      }
      const requestUrl = new URL(url);
      requestUrl.search = searchParams.toString();
      const response = await fetch(requestUrl);
      return await response.text();
    },
    { revalidateOnMount: true },
  );

  const data = xml ? getAdData(xml as string) : null;

  useSWRImmutable(data ? data.logUrl : null, fetch);

  const ref = useRef<HTMLDivElement>(null);
  const lastPrependRef = useRef<HTMLDivElement>();
  const lastAppendRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const container = document.createElement('div');
    if (data?.prepend) {
      container.innerHTML = substituteTimestamp(data.prepend);
      if (lastPrependRef.current) {
        lastPrependRef.current.remove();
      }
      const child = container.firstChild as HTMLDivElement;
      lastPrependRef.current = child;
      ref.current.prepend(child);
    }
    if (data?.append) {
      container.innerHTML = substituteTimestamp(data.append);
      if (lastAppendRef.current) {
        lastAppendRef.current.remove();
      }
      const child = container.lastChild as HTMLDivElement;
      lastAppendRef.current = child;
      ref.current.append(child);
    }
  }, [data?.prepend, data?.append]);

  if (data) {
    const { logUrl, ...rest } = data;
    return {
      data: rest,
      update,
      loading: isLoading,
      ref,
    };
  }

  return {
    data: null,
    update,
    loading: isLoading,
  };
}

function parseXml(xml: string): HTMLElement {
  return new DOMParser().parseFromString(xml, 'text/xml').documentElement;
}

function getAdData(xml: string) {
  const doc = parseXml(xml);
  const logUrl = doc.querySelector(LOG_URL_KEY)?.textContent;
  const clickUrl = doc.querySelector(CLICK_URL_KEY)?.textContent;
  const creativeUrl = doc.querySelector(CREATIVE_URL_KEY)?.textContent;
  const width = doc.querySelector(WIDTH_KEY)?.textContent;
  const height = doc.querySelector(HEIGHT_KEY)?.textContent;
  const append = doc.querySelector(APPEND_KEY)?.textContent ?? null;
  const prepend = doc.querySelector(PREPEND_KEY)?.textContent ?? null;

  if (!logUrl || !clickUrl || !creativeUrl || !width || !height) {
    return null;
  } else {
    return {
      clickUrl,
      creativeUrl,
      width: Number(width),
      height: Number(height),
      logUrl,
      prepend,
      append,
    };
  }
}

function substituteTimestamp(str: string) {
  return str.replace(
    /\$timestamp/gim,
    Math.floor(Date.now() / 1000).toString(),
  );
}

const REVIVE_ID = 'ea8588616f672ecb71c9b316c29e7f22';

export function useInpageAd() {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `//oa.afishamedia.net/www/delivery/asyncjs.php`;
    script.onload = () => {
      (window as any).reviveAsync?.[REVIVE_ID]?.main();
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
}
