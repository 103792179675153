import useTranslation from 'next-translate/useTranslation';
import { Box } from '@afishauz/ui-kit/box';
import { List, ListItemMeta, ListItemTitle } from '@afishauz/ui-kit/list';
import { PublishedAt } from '@afishauz/core/widgets/published-at';
import {
  DelimitedList,
  DelimitedListItem,
} from '@afishauz/ui-kit/delimited-list';
import { LoadingButton } from '@afishauz/core/widgets/loading-button';
import { ListItem, ListTitle } from '@/layouts/header/search/list';
import {
  getMaterialMainImage,
  getMaterialTypeName,
} from '@afishauz/api/materials';
import { parseLocale } from '@afishauz/core/config';
import { useSearchMaterials } from '@afishauz/core/pages/search';

interface Props {
  query: string;
  fuzzy?: boolean;
}

export const Materials = ({ query, fuzzy = false }: Props) => {
  const { t, lang } = useTranslation();
  const locale = parseLocale(lang);
  const { list, isValidating, hasMoreItems, getNextPage, totalItems } =
    useSearchMaterials({ q: query, fuzzy, locale });

  if (totalItems === 0) {
    return null;
  }

  return (
    <Box component='section'>
      <ListTitle>{t('layouts.search.materials')}</ListTitle>
      <List>
        {list.map(material => (
          <ListItem
            link={{
              href: material.permalinkCanonical,
              native: material.tildaSubstitute,
            }}
            img={{
              src: getMaterialMainImage(material, 'extra_small'),
              alt: material.title,
              size: 'sm',
            }}
            key={material['@id']}>
            <ListItemTitle title={material.title} size='sm'>
              {material.title}
            </ListItemTitle>
            <ListItemMeta>
              <DelimitedList>
                <DelimitedListItem>
                  <PublishedAt date={new Date(material.publishedAt)} />
                </DelimitedListItem>
                <DelimitedListItem>
                  {getMaterialTypeName(material, t)}
                </DelimitedListItem>
              </DelimitedList>
            </ListItemMeta>
          </ListItem>
        ))}
      </List>
      {hasMoreItems && (
        <LoadingButton
          loading={isValidating}
          onClick={getNextPage}
          disabled={isValidating}
          className='mt-3'
          size='sm'
        />
      )}
    </Box>
  );
};
