import { useRef, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useUser } from '@afishauz/core/utils/user';
import { Container } from '@afishauz/ui-kit/container';
import { Link } from '@afishauz/core/widgets/link';
import { Avatar } from '@afishauz/ui-kit/avatar';
import { Button } from '@afishauz/ui-kit/button';
import { UserDropdown } from '@/layouts/header/user-dropdown';
import { AuthModal } from '@/layouts/header/auth-modal';
import { TopNavigation } from '@/layouts/header/top-navigation';
import { MenuItem } from '@afishauz/api/menu-items';
import { MainNavigation } from '@/layouts/header/main-navigation';
import { Search } from '@/layouts/header/search';
import { Afishauz } from '@afishauz/icons';

interface Props {
  menuItems: MenuItem[];
}

export const Header = ({ menuItems }: Props) => {
  const userDropdownRef = useRef<HTMLDivElement>(null);
  const [authModalOpened, setAuthModalOpened] = useState(false);
  const { user } = useUser();
  const { t } = useTranslation();

  return (
    <header className='mb-16'>
      <Container>
        <div className='flex items-center py-6'>
          <Link href='/' className='mr-9' aria-label='go to home page'>
            <Afishauz width={172} className='text-afisha' />
          </Link>
          <Search />
          <TopNavigation />
          <div className='ml-auto'>
            {user ? (
              <>
                <Avatar
                  src={user.avatarUrl}
                  alt={user.fullName}
                  ref={userDropdownRef}
                  size='lg'
                  className='cursor-pointer'
                />
                <UserDropdown user={user} trigger={userDropdownRef} />
              </>
            ) : (
              <>
                <Button
                  color='primary'
                  variant='outlined'
                  onClick={() => {
                    setAuthModalOpened(true);
                  }}>
                  {t('layouts.header.log_in')}
                </Button>
                <AuthModal
                  show={authModalOpened}
                  onClose={() => {
                    setAuthModalOpened(false);
                  }}
                />
              </>
            )}
          </div>
        </div>
        <MainNavigation menuItems={menuItems} />
      </Container>
    </header>
  );
};
