import { formatISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const getTashkentDate = (date: Date) => {
  return utcToZonedTime(date.toISOString(), 'Asia/Tashkent');
};

export const getTashkentISODateString = (date: Date) => {
  return formatISO(getTashkentDate(date));
};

export const getNormalizedISODateString = (date: Date) => {
  const tashkentDate = getTashkentDate(date);
  tashkentDate.setHours(0, 0, 0, 0);
  return formatISO(tashkentDate);
};

export const getOptimizedDateString = (date: Date = new Date()) => {
  const tashkentDate = getTashkentDate(date);
  tashkentDate.setSeconds(0, 0);
  return formatISO(tashkentDate);
};
