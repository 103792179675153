import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { Text, TextProps } from '../text';

export type ListItemMetaProps = TextProps;

export const ListItemMeta = forwardRef<HTMLDivElement, ListItemMetaProps>(
  ({ className, ...rest }, ref) => {
    return (
      <Text
        ref={ref}
        className={twMerge('text-gray-400 text-[13px] mt-1', className)}
        {...rest}
      />
    );
  },
);

ListItemMeta.displayName = 'ListItemMeta';
