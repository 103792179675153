import { forwardRef } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Button, ButtonProps } from '@afishauz/ui-kit/button';
import { Spinner } from '@afishauz/ui-kit/spinner';

export interface LoadingButtonProps extends ButtonProps {
  loading?: boolean;
}

export const LoadingButton = forwardRef<HTMLButtonElement, LoadingButtonProps>(
  ({ loading, ...rest }, ref) => {
    const { t } = useTranslation();
    return (
      <Button {...rest} fullWidth ref={ref}>
        {t('elements.loading_button.text')}
        {loading && (
          <>
            &nbsp;
            <Spinner size={16} />
          </>
        )}
      </Button>
    );
  },
);

LoadingButton.displayName = 'LoadingButton';
