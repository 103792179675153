import { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { toast } from 'react-hot-toast';
import { parseCookies, setCookie } from 'nookies';
import { subscribe, usePushNotifications } from '@afishauz/core/utils/fcm';
import { Button } from '@afishauz/ui-kit/button';
import * as Dialog from '@radix-ui/react-dialog';
import { parseLocale } from '@afishauz/core/config';

export const PushNotifDialog = () => {
  const [open, setOpen] = useState(!parseCookies().pushDialogShown);
  const { t, lang } = useTranslation();
  const locale = parseLocale(lang);
  const { status, updateStatus } = usePushNotifications();

  if (status !== 'unsubscribed') {
    return null;
  }

  return (
    <Dialog.Root modal={false} open={open}>
      <Dialog.Portal>
        <Dialog.Content className='fixed top-4 left-8 w-72 p-3.5 overflow-hidden rounded-md shadow-afisha-md z-40 bg-white data-[state="open"]:animate-fade-in data-[state="closed"]:animate-fade-out'>
          <Dialog.Title className='text-lg font-semibold'>
            {t('elements.push_notifications.title')}
          </Dialog.Title>
          <Dialog.Description className='mt-3 text-sm text-gray-500'>
            {t('elements.push_notifications.subtitle')}
          </Dialog.Description>
          <div className='mt-3 flex items-center gap-x-3'>
            <Button
              color='primary'
              size='sm'
              className='flex-1'
              onClick={async () => {
                setOpen(false);
                setCookie(null, 'pushDialogShown', 'true', {
                  maxAge: 100 * 365 * 24 * 60 * 60,
                  path: '/',
                });
                const success = await subscribe(locale);
                await updateStatus();
                if (success) {
                  toast.success(
                    t('elements.push_notifications.messages.success'),
                  );
                } else {
                  toast.error(t('elements.push_notifications.messages.error'));
                }
              }}>
              {t('elements.push_notifications.subscribe')}
            </Button>
            <Button
              size='sm'
              className='flex-1'
              onClick={() => {
                setOpen(false);
                setCookie(null, 'pushDialogShown', 'true', {
                  maxAge: 60 * 24 * 60 * 60,
                  path: '/',
                });
              }}>
              {t('elements.push_notifications.not_now')}
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
