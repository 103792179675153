import { forwardRef, HTMLAttributes, PropsWithChildren } from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import { createPolymorphicComponent } from '../utils/create-polymorphic-component';
import { twMerge } from 'tailwind-merge';

const text = cva([], {
  variants: {
    size: {
      xs: ['text-xs'],
      sm: ['text-sm'],
      md: ['text-base'],
      lg: ['text-lg'],
      xl: ['text-xl'],
      '2xl': ['text-2xl'],
      '3xl': ['text-3xl'],
      '4xl': ['text-4xl'],
    },
    smoothing: {
      auto: ['subpixel-antialiased'],
      antialiased: ['antialiased'],
    },
    italic: {
      true: ['italic'],
      false: ['non-italic'],
    },
    weight: {
      100: ['font-thin'],
      200: ['font-extralight'],
      300: ['font-light'],
      400: ['font-normal'],
      500: ['font-medium'],
      600: ['font-semibold'],
      700: ['font-bold'],
      800: ['font-extrabold'],
      900: ['font-black'],
    },
    lineClamp: {
      1: ['line-clamp-1'],
      2: ['line-clamp-2'],
      3: ['line-clamp-3'],
      4: ['line-clamp-4'],
      5: ['line-clamp-5'],
      6: ['line-clamp-6'],
      none: ['line-clamp-none'],
    },
    align: {
      left: ['text-left'],
      center: ['text-center'],
      right: ['text-right'],
      justify: ['text-justify'],
      start: ['text-start'],
      end: ['text-end'],
    },
    color: {
      inherit: ['text-inherit'],
      transparent: ['text-transparent'],
      black: ['text-black'],
      white: ['text-white'],
    },
    decoration: {
      underline: ['underline'],
      overline: ['overline'],
      'line-through': ['line-through'],
      none: ['no-underline'],
    },
    transform: {
      uppercase: ['uppercase'],
      lowercase: ['lowercase'],
      capitalize: ['capitalize'],
      none: ['normal-case'],
    },
    truncate: {
      true: ['truncate'],
      false: [],
    },
    verticalAlign: {
      baseline: ['align-baseline'],
      top: ['align-top'],
      middle: ['align-middle'],
      bottom: ['align-bottom'],
      textTop: ['align-text-top'],
      textBottom: ['align-text-bottom'],
      sub: ['align-sub'],
      sup: ['align-super'],
    },
    whitespace: {
      normal: ['whitespace-normal'],
      nowrap: ['whitespace-nowrap'],
      pre: ['whitespace-pre'],
      'pre-line': ['whitespace-pre-line'],
      'pre-wrap': ['whitespace-pre-wrap'],
      'break-spaces': ['whitespace-break-spaces'],
    },
    wordBreak: {
      normal: ['break-normal'],
      words: ['break-words'],
      all: ['break-all'],
      keep: ['break-keep'],
    },
  },
  defaultVariants: {
    size: 'md',
    smoothing: 'auto',
    italic: false,
    weight: 400,
    lineClamp: 'none',
    align: 'left',
    decoration: 'none',
    color: 'inherit',
    transform: 'none',
    truncate: false,
    verticalAlign: 'baseline',
    whitespace: 'normal',
    wordBreak: 'normal',
  },
});

export type TextProps = VariantProps<typeof text> &
  PropsWithChildren<HTMLAttributes<HTMLDivElement>>;

const _Text = forwardRef<
  HTMLDivElement,
  { component: any; className?: string } & TextProps
>(
  (
    {
      component,
      size,
      smoothing,
      italic,
      weight,
      lineClamp,
      align,
      color,
      decoration,
      transform,
      truncate,
      verticalAlign,
      whitespace,
      wordBreak,
      className,
      ...rest
    },
    ref,
  ) => {
    const Element = component || 'div';
    const props = {
      ref,
      className: twMerge(
        text({
          size,
          smoothing,
          italic,
          weight,
          lineClamp,
          align,
          color,
          decoration,
          transform,
          truncate,
          verticalAlign,
          whitespace,
          wordBreak,
          className,
        }),
      ),
      ...rest,
    };
    return <Element {...props} />;
  },
);

_Text.displayName = 'Text';

export const Text = createPolymorphicComponent<'div', TextProps>(_Text);
