import {
  getCollection,
  GetCollectionOptions,
  PaginationParams,
} from '../collection';
import { Locale, OrderType, RelationGroup } from '../config';
import { Menu } from './types';

export interface GetMenusParams extends PaginationParams {
  'items.isVisible'?: boolean;
  'groups[]'?: RelationGroup[];
  'order[translations.name]'?: OrderType;
  slug?: string;
  'slug[]'?: string[];
  'translations.locale'?: Locale;
  'translations.locale[]'?: Locale[];
  'translations.name'?: string;
}

export async function getMenus(
  params: GetMenusParams = {},
  options: GetCollectionOptions = {},
) {
  return await getCollection<Menu>('api/menus', params, options);
}
