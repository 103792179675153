import { useCallback } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { ru, uz } from 'date-fns/locale';
import { format as dateFnsFormat } from 'date-fns';
import { getTashkentDate } from '@afishauz/lib/date';
import { Locale } from '@afishauz/api/config';

interface Config {
  locale?: Locale;
}

export const useDateTranslator = ({ locale }: Config = {}) => {
  const { lang } = useTranslation();

  const format = useCallback(
    (date: Date, pattern: string) => {
      const loc = locale ?? lang;
      const formatted = dateFnsFormat(
        getTashkentDate(new Date(date)),
        pattern,
        {
          locale: loc === 'uz' ? uz : ru,
        },
      ).toLowerCase();
      const [d, m] = pattern.split(/\s/);
      if (d === 'd' && m === 'MMMM' && loc === 'uz') {
        return formatted.replace(/\s/, '-');
      }
      return formatted;
    },
    [lang, locale],
  );

  return {
    format,
  };
};
