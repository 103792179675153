import { PropsWithChildren, useEffect, useState } from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import { Portal } from '../portal';
import { Spinner } from '../spinner';

const overlay = cva(
  [
    'fixed',
    'top-0',
    'right-0',
    'bottom-0',
    'left-0',
    'z-50',
    'animate-fade-in',
    'flex',
    'justify-center',
    'items-center',
    'select-none',
  ],
  {
    variants: {
      theme: {
        light: ['bg-white/70'],
        dark: ['bg-black/70'],
        transparent: ['bg-none'],
        black: ['bg-black'],
      },
      blur: {
        none: ['backdrop-blur-none'],
        sm: ['backdrop-blur-sm'],
        md: ['backdrop-blur'],
        lg: ['backdrop-blur-lg'],
      },
    },
    defaultVariants: {
      theme: 'dark',
      blur: 'md',
    },
  },
);

export interface OverlayProps
  extends PropsWithChildren,
    VariantProps<typeof overlay> {
  show?: boolean;
  showLoadingIndicator?: boolean;
  onClick?: () => void;
}

export const Overlay = ({
  show = false,
  showLoadingIndicator = false,
  theme = 'dark',
  children,
  onClick = () => {},
  blur = 'none',
}: OverlayProps) => {
  const [visible, setVisible] = useState(show);

  useEffect(() => {
    setVisible(show);
    if (show) {
      document.body.classList.add('overflow-hidden');
      return () => {
        document.body.classList.remove('overflow-hidden');
      };
    }
  }, [show]);

  if (!visible) {
    return null;
  }

  return (
    <Portal>
      <div onClick={onClick} className={overlay({ theme, blur })}>
        {children}
        {showLoadingIndicator && <Spinner size={24} />}
      </div>
    </Portal>
  );
};
