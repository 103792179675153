import { Component, PropsWithChildren } from 'react';
import Trans from 'next-translate/Trans';
import { Container } from '@afishauz/ui-kit/container';

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<PropsWithChildren, State> {
  state: State = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    console.log(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='h-screen flex justify-center items-center'>
          <div>
            <Container>
              <h1 className='text-center text-2xl text-afisha'>
                <Trans i18nKey='layouts.error_boundary.title' ns='common' />
              </h1>
              <div className='mt-4 text-gray-500 text-center'>
                <Trans
                  i18nKey='layouts.error_boundary.message'
                  components={{ 1: <a href='/' className='link' /> }}
                  ns='common'
                />
              </div>
            </Container>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
