import { createContext, useContext } from 'react';
import { User } from '@afishauz/api/users';

export interface AppContextInterface {
  user: User | null;
  updateUser: (user: User) => void;
}

const UserContext = createContext<AppContextInterface>({
  user: null,
  updateUser: () => {},
});

export const UserProvider = UserContext.Provider;

export const useUser = () => {
  return useContext(UserContext);
};
