import { useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { Theme, ThemeDevice } from '@afishauz/api/themes';
import { API_ORIGIN, Locale } from '@afishauz/api/config';

const extractId = (iri: string) => Number(/(\d+)$/.exec(iri)?.[1] ?? -1);

export const useThemes = (themes: Theme[], device: ThemeDevice) => {
  const router = useRouter();
  const locale = router.locale;
  const { pathname } = new URL(router.asPath, API_ORIGIN);
  const filteredThemes = useMemo(
    () =>
      themes.filter(
        theme =>
          theme.devices.includes(device) &&
          theme.urlPattern === pathname &&
          theme.locales.includes(locale as Locale),
      ),
    [themes, pathname, locale, device],
  );

  useEffect(() => {
    const runScripts = () => {
      const scripts: HTMLScriptElement[] = [];
      filteredThemes.forEach(theme => {
        theme.scripts.forEach(({ url, '@id': iri }) => {
          const script = document.createElement('script');
          script.src = url;
          const id = extractId(iri);
          const attributes = theme.scriptAttributes[id] ?? {};
          Object.entries(attributes).forEach(([name, value]) => {
            script.setAttribute(name, value);
          });
          scripts.push(document.body.appendChild(script));
        });
      });
      return () => {
        scripts.forEach(script => {
          document.body.removeChild(script);
        });
      };
    };
    const applyStyles = () => {
      const links: HTMLLinkElement[] = [];
      filteredThemes.forEach(theme => {
        theme.stylesheets.forEach(({ url, '@id': iri }) => {
          const link = document.createElement('link');
          link.rel = 'stylesheet';
          link.href = url;
          const id = extractId(iri);
          const attributes = theme.stylesheetAttributes[id] ?? {};
          Object.entries(attributes).forEach(([name, value]) => {
            link.setAttribute(name, value);
          });
          links.push(document.head.appendChild(link));
        });
      });
      return () => {
        links.forEach(link => {
          document.head.removeChild(link);
        });
      };
    };
    const removeScripts = runScripts();
    const removeLinks = applyStyles();
    return () => {
      removeScripts();
      removeLinks();
    };
  }, [filteredThemes]);
};
