import useSWRInfinite, { SWRInfiniteConfiguration } from 'swr/infinite';
import { useCallback, useMemo } from 'react';
import {
  Collection,
  getCollection,
  GetCollectionOptions,
  GetCollectionParams,
} from './collection';

export type SWROptions = Omit<SWRInfiniteConfiguration, 'fallbackData'>;

export type UseCollectionOptions<T, P = GetCollectionParams> = {
  keyParams?: P;
  nonKeyParams?: P;
  initialData?: Collection<T>;
  postpone?: boolean;
  tag?: string;
};

export function useCollection<T>(
  endpoint: string,
  {
    keyParams = {},
    nonKeyParams = {},
    initialData,
    postpone = false,
    tag = '',
  }: UseCollectionOptions<T> = {},
  requestOptions: GetCollectionOptions = {},
  {
    revalidateOnFocus = false,
    revalidateFirstPage = false,
    revalidateOnMount,
    ...swrConfig
  }: SWROptions = {},
) {
  const result = useSWRInfinite<Collection<T>>(
    (index, prevPageData) => {
      if (
        (prevPageData && prevPageData['hydra:member'].length === 0) ||
        postpone
      ) {
        return null;
      }
      return [{ ...keyParams, page: index + 1 }, endpoint, tag];
    },
    ([params]) => {
      return getCollection(
        endpoint,
        { ...params, ...nonKeyParams },
        requestOptions,
      );
    },
    {
      ...swrConfig,
      revalidateOnFocus,
      revalidateFirstPage,
      revalidateOnMount: revalidateOnMount ?? !initialData,
      fallbackData: initialData ? [initialData] : undefined,
    },
  );
  const { data, error, isLoading, isValidating, mutate, setSize, size } =
    result;

  const fetchedItems = useMemo(
    () =>
      (data ?? []).reduce(
        (acc, collection) => acc + collection['hydra:member'].length,
        0,
      ),
    [data],
  );

  const totalItems = (data ?? []).at(0)?.['hydra:totalItems'] ?? 0;

  const getNextPage = useCallback(() => {
    setSize(size => size + 1);
  }, [setSize]);

  const reset = useCallback(() => {
    setSize(1);
  }, [setSize]);

  const list = useMemo(
    () => (data ?? []).flatMap(collection => collection['hydra:member']),
    [data],
  );

  return {
    pages: data,
    list,
    error,
    isValidating,
    isLoadingFirstPage: !data && isLoading,
    mutate,
    getNextPage,
    reset,
    totalItems,
    currentPage: size,
    hasMoreItems: totalItems > fetchedItems,
  };
}
