import { ChangeEvent, FormEvent, useRef, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useUser } from '@afishauz/core/utils/user';
import { updateUser as updateProfile } from '@afishauz/api/users';
import { parseLocale } from '@afishauz/core/config';
import { Avatar } from '@afishauz/ui-kit/avatar';
import { Spinner } from '@afishauz/ui-kit/spinner';
import { TextInput } from '@afishauz/ui-kit/text-input';
import { Button } from '@afishauz/ui-kit/button';
import { Textarea } from '@afishauz/ui-kit/textarea';
import { twMerge } from 'tailwind-merge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { Checkbox } from '@afishauz/ui-kit/checkbox';

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
}

const imageToBase64String = (
  file: File,
): Promise<string | ArrayBuffer | null> => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
  });
};

export const Settings = ({ isOpen, onClose = () => {} }: Props) => {
  const { user, updateUser } = useUser();
  const formRef = useRef<HTMLFormElement>(null);
  const [avatarUrl, setAvatarUrl] = useState(user?.avatarUrl);
  const [base64Avatar, setBase64Avatar] = useState<string>();
  const [avatarRemove, setAvatarRemove] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t, lang } = useTranslation();
  const locale = parseLocale(lang);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      setLoading(true);
      const fullName = formRef.current?.fullName.value;
      const email = formRef.current?.email.value;
      const biography = formRef.current?.bio.value;
      const telegramUsername = formRef.current?.telegramUsername.value;
      const avatarFile = base64Avatar?.slice(base64Avatar.indexOf(',') + 1);
      const updates = {
        email,
        telegramUsername,
        avatarFile,
        avatarRemove,
        translations: {
          [locale]: {
            fullName,
            biography,
            locale,
          },
        },
      };
      if (user) {
        const id = /(\d+)$/.exec(user['@id'])?.[1];
        if (id) {
          const data = await updateProfile(Number(id), updates);
          if (data) {
            updateUser(data);
          }
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const handleAvatarFileChange = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const base64 = await imageToBase64String(file);
      setBase64Avatar(base64?.toString());
      setAvatarUrl(URL.createObjectURL(file));
    }
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const shouldRemove = event.target.checked;
    if (shouldRemove) {
      setAvatarUrl(undefined);
      setBase64Avatar(undefined);
    }
    setAvatarRemove(shouldRemove);
  };

  return (
    <div className={twMerge('overflow-y-auto hidden p-4', isOpen && 'block')}>
      <form
        className='w-full max-w-full flex flex-col gap-y-3'
        ref={formRef}
        onSubmit={handleSubmit}>
        <div className='flex items-center'>
          <button
            className='h-full -ml-4 px-4 block py-4 text-gray-500 hover:text-black transition-colors'
            onClick={onClose}>
            <FontAwesomeIcon icon={faChevronLeft} aria-label='back' />
          </button>
          <Avatar src={avatarUrl} alt={user?.fullName} className='mr-3' />
          <div>
            <label
              className={twMerge(
                'block text-[13px] cursor-pointer',
                avatarRemove && 'cursor-default text-gray-500',
              )}>
              <input
                type='file'
                accept='image/jpeg, image/jpg, image/png, image/gif'
                onChange={handleAvatarFileChange}
                disabled={avatarRemove}
                className='hidden'
              />
              {t('layouts.user_dropdown.settings.change_avatar')}
            </label>
            <label className='flex gap-x-2 items-center text-[13px] mt-1 cursor-pointer transition-colors'>
              {t('layouts.user_dropdown.settings.delete_avatar')}
              <Checkbox
                color='primary'
                size='sm'
                checked={avatarRemove}
                onChange={handleCheckboxChange}
              />
            </label>
          </div>
        </div>
        <div>
          <label htmlFor='fullName' className='text-xs text-gray-500'>
            {t('layouts.user_dropdown.settings.full_name')}
          </label>
          <TextInput
            type='text'
            size='sm'
            id='fullName'
            name='fullName'
            defaultValue={user?.fullName}
            className='w-full'
          />
        </div>
        <div>
          <label htmlFor='email' className='text-xs text-gray-500'>
            Email
          </label>
          <TextInput
            type='email'
            size='sm'
            id='email'
            name='email'
            defaultValue={user?.email}
            className='w-full'
          />
        </div>
        <div>
          <label htmlFor='bio' className='text-xs text-gray-500'>
            {t('layouts.user_dropdown.settings.bio')}
          </label>
          <Textarea id='bio' name='bio' defaultValue={user?.biography ?? ''} />
        </div>
        <div>
          <label htmlFor='telegram-username' className='text-xs text-gray-500'>
            Telegram username
          </label>
          <TextInput
            type='text'
            size='sm'
            id='telegram-username'
            name='telegramUsername'
            defaultValue={user?.telegramUsername ?? ''}
            className='w-full'
          />
        </div>
        <Button size='sm' color='primary'>
          {t('layouts.user_dropdown.settings.save')}
          {loading && (
            <>
              &nbsp;
              <Spinner size={14} />
            </>
          )}
        </Button>
      </form>
    </div>
  );
};
