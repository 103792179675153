import { GetCollectionOptions } from '../collection';
import {
  SWROptions,
  useCollection,
  UseCollectionOptions,
} from '../use-collection';
import {
  GetPerformancesScheduleParams,
  SearchPerformancesParams,
} from './service';
import { Performance } from './types';

export function usePerformancesSchedule(
  options: UseCollectionOptions<
    Performance,
    Omit<GetPerformancesScheduleParams, 'page'>
  > = {},
  requestOptions: GetCollectionOptions = {},
  swrConfig: SWROptions = {},
) {
  return useCollection<Performance>(
    'api/performances/schedule',
    options,
    requestOptions,
    swrConfig,
  );
}

export function usePerformancesSearch(
  options: UseCollectionOptions<
    Performance,
    Omit<SearchPerformancesParams, 'page'>
  > = {},
  requestOptions: GetCollectionOptions = {},
  swrConfig: SWROptions = {},
) {
  return useCollection<Performance>(
    'api/performances/search',
    options,
    requestOptions,
    swrConfig,
  );
}
