import { PropsWithChildren } from 'react';
import { useRouter } from 'next/router';
import { parseLocale, AD_ZONES, LOCALE } from '@afishauz/core/config';
import { Menu } from '@afishauz/api/menus';
import { Header } from '@/layouts/header';
import { Footer } from '@/layouts/footer';
import { HorizontalBanner } from '@/templates/shared/horizontal-banner';

interface Props extends PropsWithChildren {
  menus: Menu[];
}

export const AppLayout = ({ menus, children }: Props) => {
  const router = useRouter();
  const locale = parseLocale(router.locale);

  const mainMenuItems =
    menus.find(
      menu => menu.slug === (locale === LOCALE.uz ? 'main-uz' : 'main'),
    )?.items ?? [];
  const footerMenuItems =
    menus.find(menu => menu.slug === 'footer')?.items ?? [];

  return (
    <>
      <div className='h-[300px]'>
        <div className='fixed w-full'>
          <HorizontalBanner
            zoneId={AD_ZONES[locale].DESKTOP_TOP}
            spacing='none'
            hideOnPaths={[
              '/euro-2024',
              '/euro-2024/standings',
              '/euro-2024/calendar',
              /\/euro-2024\/match\/\d+/i,
            ]}
          />
        </div>
      </div>
      <div className='relative z-10 bg-white'>
        <Header menuItems={mainMenuItems} />
        <main>{children}</main>
        <Footer menuItems={footerMenuItems} />
      </div>
    </>
  );
};
