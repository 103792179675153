import NextApp from 'next/app';
import type {
  AppProps as NextAppProps,
  AppContext as NextAppContext,
} from 'next/app';
import * as menusApi from '@afishauz/api/menus';
import * as themesApi from '@afishauz/api/themes';
import { getTashkentISODateString } from '@afishauz/lib/date';
import { parseLocale } from '../config';
import { Locale } from '@afishauz/api/config';

export interface AppProps extends NextAppProps {
  menus: menusApi.Menu[];
  themes: themesApi.Theme[];
}

export function createGetInitialProps() {
  return async (appContext: NextAppContext) => {
    const locale = parseLocale(appContext.ctx.locale);
    const menus = await getMenus({ locale });
    const themes = await getThemes();
    return {
      ...(await NextApp.getInitialProps(appContext)),
      menus: menus['hydra:member'],
      themes: themes['hydra:member'],
    };
  };
}

export async function getMenus({ locale }: { locale: Locale }) {
  return await menusApi.getMenus({
    locale,
    'groups[]': ['menu:items', 'menu_item:read', 'menu_item:child_menu_items'],
  });
}

export async function getThemes() {
  const today = getTashkentISODateString(new Date());
  return await themesApi.getThemes({
    'status[]': ['ACTIVE'],
    'startedAt[before]': today,
    'endedAt[after]': today,
    'groups[]': ['theme_script:read', 'theme_stylesheet:read'],
  });
}
