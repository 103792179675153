import { getMediaObjectUrl, MediaObjectVariant } from '../media-objects';
import { getTashkentDate } from '@afishauz/lib/date';
import { add, getDayOfYear, parse } from 'date-fns';
import { DaysOfWeek, Place } from './types';
import { Locale } from '../config';

export function getPlaceMainImage(place: Place, variant: MediaObjectVariant) {
  const placeLogo =
    (place.mainMediaObject &&
      getMediaObjectUrl(place.mainMediaObject, variant)) ??
    place.mainMediaObject?.url;
  if (placeLogo) {
    return placeLogo;
  }
  const firstImage =
    place.images &&
    place.images.length > 0 &&
    getMediaObjectUrl(place.images[0].mediaObject, variant);
  if (firstImage) {
    return firstImage;
  }
  return;
}

export function getPlaceLink(place: Place) {
  return `/places/${place.slug}`;
}

export function getPlaceWorkingHours(place: Place) {
  const { schedule } = place;
  if (Array.isArray(schedule) && schedule.length === 0) {
    return null;
  }
  const day = getTashkentDate(new Date()).getDay() as DaysOfWeek;
  const daySchedule = schedule[day];
  if (
    !daySchedule ||
    (Array.isArray(daySchedule) && daySchedule.length === 0)
  ) {
    return null;
  }
  if (daySchedule === 'day_off' || daySchedule === 'round_the_clock') {
    return daySchedule;
  }
  return `${daySchedule.open}-${daySchedule.close}`;
}

export const isPlaceOpenNow = (place: Place) => {
  try {
    const { schedule } = place;
    if (Array.isArray(schedule) && schedule.length === 0) {
      return null;
    }
    const day = getTashkentDate(new Date()).getDay() as DaysOfWeek;
    const daySchedule = schedule[day];
    if (
      !daySchedule ||
      (Array.isArray(daySchedule) && daySchedule.length === 0)
    ) {
      return null;
    }
    if (daySchedule === 'day_off') {
      return false;
    }
    if (daySchedule === 'round_the_clock') {
      return true;
    }
    const tashkentTime = getTashkentDate(new Date());
    const openTime = getTashkentDate(
      new Date(tashkentTime.toDateString() + ',' + daySchedule.open),
    );
    let closeTime = getTashkentDate(
      new Date(tashkentTime.toDateString() + ',' + daySchedule.close),
    );
    if (closeTime < openTime) {
      closeTime = add(closeTime, { days: 1 });
    }
    return tashkentTime >= openTime && tashkentTime < closeTime;
  } catch {
    return false;
  }
};

interface ContactValue {
  href: string;
  label: string;
}

function isValidPhoneNumber(value: string) {
  return value.length === 12 && /998\d{9}/.test(value);
}

export function getNormalizedContacts(place: Place) {
  return (place.contacts ?? []).reduce(
    (acc: Record<string, ContactValue[]>, contact) => {
      const { type, value } = contact;
      const getContactValue = (value: ContactValue) => {
        const values = acc[type];
        return {
          ...acc,
          [type]: values ? [...values, value] : [value],
        };
      };
      switch (type) {
        case 'PHONE': {
          if (isValidPhoneNumber(value)) {
            return getContactValue({
              href: `tel:+${value}`,
              label: `+${value}`,
            });
          }
          return acc;
        }
        case 'WEBSITE': {
          const pattern = /^https?:\/\/(www\.)?(([a-z0-9_-]|\.)+)/i;
          return getContactValue({
            href: value,
            label: pattern.exec(value)?.[2] ?? value,
          });
        }
        case 'INSTAGRAM': {
          const pattern = /^https?:\/\/(www\.)?instagram\.com\/([a-z0-9_-]+)/i;
          return getContactValue({
            href: value,
            label: pattern.exec(value)?.[2] ?? value,
          });
        }
        case 'TELEGRAM': {
          const pattern =
            /^https?:\/\/(www\.)?(t|telegram)\.me\/([a-z0-9_-]+)/i;
          return getContactValue({
            href: value,
            label: pattern.exec(value)?.[3] ?? value,
          });
        }
        case 'FACEBOOK': {
          const pattern =
            /^https?:\/\/(www\.)?facebook\.com\/(([a-z0-9_-]|\.)+)/i;
          return getContactValue({
            href: value,
            label: pattern.exec(value)?.[2] ?? value,
          });
        }
        default: {
          return acc;
        }
      }
    },
    {},
  );
}

export function getNormalizedAttributes(place: Place, locale: Locale) {
  return (place.attributes ?? []).reduce(
    (acc: Record<string, string>, attribute) => {
      let value = attribute.translations?.[locale]?.value;

      if (attribute.field?.inputType === 'BOOLEAN') {
        if (attribute.value === '0') {
          value = 'Нет';
        }

        if (attribute.value === '1') {
          value = 'Да';
        }
      }

      if (
        attribute.field?.inputType === 'SELECT_MULTIPLE' ||
        attribute.field?.inputType === 'SELECT_SINGLE'
      ) {
        const selected = attribute.value?.split(',') ?? [];

        value = attribute.field?.choices
          ?.filter(item =>
            selected
              .map(val => val.replace(/[\[|\]]/, ''))
              .includes(JSON.stringify(item.slug)),
          )
          .map(item => item.translations[locale])
          .join(', ');
      }

      if (!!attribute.field?.name && !!value) {
        return { ...acc, [attribute.field.name]: value };
      }
      return acc;
    },
    {},
  );
}

export function areTicketsHidden(place: Place) {
  return place.ticketUrlsHiddenPeriods.some(({ type, startedAt, endedAt }) => {
    const now = new Date();
    if (type === 'TIME') {
      const startDate = parse(startedAt, 'HH:mm', new Date());
      const endDate = parse(endedAt, 'HH:mm', new Date());
      now.setSeconds(0, 0);
      return now >= startDate && now <= endDate;
    }
    if (type === 'DAYS_OF_WEEK') {
      const dayOfWeek = now.getDay();
      return dayOfWeek >= startedAt && dayOfWeek <= endedAt;
    }
    if (type === 'DAYS_OF_MONTH') {
      const dayOfMonth = now.getDate();
      return dayOfMonth >= startedAt && dayOfMonth <= endedAt;
    }
    if (type === 'DAYS_OF_YEAR') {
      const dayOfYear = getDayOfYear(now);
      return dayOfYear >= startedAt && dayOfYear <= endedAt;
    }
    return false;
  });
}
