import {
  getCollection,
  GetCollectionOptions,
  PaginationParams,
} from '../collection';
import { OrderType, RelationGroup } from '../config';
import { Theme, ThemeStatus } from './types';

export interface GetThemesParams extends PaginationParams {
  'endedAt[before]'?: string;
  'endedAt[strictly_before]'?: string;
  'endedAt[after]'?: string;
  'endedAt[strictly_after]'?: string;
  'startedAt[before]'?: string;
  'startedAt[strictly_before]'?: string;
  'startedAt[after]'?: string;
  'startedAt[strictly_after]'?: string;
  'groups[]'?: RelationGroup[];
  'order[createdAt]'?: OrderType;
  'order[endedAt]'?: OrderType;
  'order[startedAt]'?: OrderType;
  'order[name]'?: OrderType;
  name?: string;
  status?: ThemeStatus;
  'status[]'?: ThemeStatus[];
  urlPattern?: string;
}

export async function getThemes(
  params: GetThemesParams = {},
  options: GetCollectionOptions = {},
) {
  return await getCollection<Theme>('api/themes', params, options);
}
