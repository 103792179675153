module.exports = {
  locales: ['ru', 'uz', 'default'],
  defaultLocale: 'default',
  localeDetection: false,
  pages: {
    '*': ['common'],
    '/': ['home'],
    '/cinema': ['cinema'],
    '/theatres': ['theatres'],
    '/catalog': ['catalog'],
    '/movies/premieres/[region]': ['premieres'],
    '/avia': ['avia'],
    '/photo': ['photo'],
    '/calendar': ['calendar'],
    '/pogoda': ['pogoda'],
    '/about': ['about'],
    '/contacts': ['contacts'],
    '/privacy': ['privacy'],
    '/reklama': ['reklama'],
    '/terms': ['terms'],
    '/feeds': ['feeds'],
    '/tag/[name]': ['tag'],
    '/[section]': ['section'],
    '/[section]/[...permalink]': ['material', 'movie', 'performance'],
    '/movies/[slug]': ['movie'],
    '/performances/[slug]': ['performance'],
    '/places/[slug]': ['place'],
    '/catalog/[placeType]': ['place_type'],
    '/catalog/[placeType]/[subPlace]': ['place_type'],
    '/404': ['404'],
    '/search': ['search'],
    '/euro-2024': ['euro-2024'],
    '/euro-2024/calendar': ['euro-2024'],
    '/euro-2024/standings': ['euro-2024'],
    '/euro-2024/match/[id]': ['euro-2024'],
    '/tourism': ['tourism'],
  },
  loadLocaleFrom: (lang, ns) =>
    import(`../assets/locales/${lang}/${ns}.json`).then(m => m.default),
  logBuild: false,
  defaultNS: 'common',
};
