import { WeatherForecast } from '@/layouts/header/weather-forecast';
import { Link } from '@afishauz/core/widgets/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons/faCalendarDays';
import { parseLocale } from '@afishauz/core/config';
import { LOCALE } from '@afishauz/core/config';
import useTranslation from 'next-translate/useTranslation';
import { Language } from '@afishauz/icons';

export const TopNavigation = () => {
  const { t, lang } = useTranslation();
  const locale = parseLocale(lang);

  return (
    <nav className='ml-9'>
      <ul className='flex'>
        <li>
          <WeatherForecast />
        </li>
        <li className='border-r border-l'>
          <Link href='/calendar' className='px-3 flex items-center gap-x-3'>
            <FontAwesomeIcon
              icon={faCalendarDays}
              aria-label='calendar'
              className='text-blue-600'
            />
            <div>
              <div className='text-sm'>
                {t('layouts.header.calendar.title')}
              </div>
              <div className='text-xs mt-1 text-gray-400'>
                {t('layouts.header.calendar.subtitle')}
              </div>
            </div>
          </Link>
        </li>
        <li>
          <Link
            href='/'
            locale={locale === LOCALE.ru ? LOCALE.uz : LOCALE.ru}
            className='pl-3 flex items-center gap-x-3'>
            <Language width={20} className='text-afisha' />
            <div>
              <div className='text-sm'>
                {t(
                  `layouts.header.locale.${
                    locale === LOCALE.ru ? LOCALE.uz : LOCALE.ru
                  }`,
                )}
              </div>
              <div className='text-xs mt-1 text-gray-400'>
                {t(`layouts.header.locale.${locale}`)}
              </div>
            </div>
          </Link>
        </li>
      </ul>
    </nav>
  );
};
