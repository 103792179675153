import { forwardRef } from 'react';
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import { CheckIcon } from '@radix-ui/react-icons';
import { cx } from 'class-variance-authority';

export type DropdownMenuProps = RadixDropdownMenu.DropdownMenuProps;
export const DropdownMenu = RadixDropdownMenu.Root;

export type DropdownMenuTriggerProps =
  RadixDropdownMenu.DropdownMenuTriggerProps;
export const DropdownMenuTrigger = RadixDropdownMenu.Trigger;

export type DropdownMenuContentProps =
  RadixDropdownMenu.DropdownMenuContentProps;

export const DropdownMenuContent = forwardRef<
  HTMLDivElement,
  DropdownMenuContentProps
>(({ sideOffset = 5, align = 'start', children, ...props }, ref) => {
  return (
    <RadixDropdownMenu.Portal>
      <RadixDropdownMenu.Content
        {...props}
        ref={ref}
        sideOffset={sideOffset}
        align={align}
        className='bg-white rounded-md shadow-afisha-md z-10 py-2'>
        <div className='max-h-80 overflow-y-auto px-2'>{children}</div>
        <RadixDropdownMenu.Arrow className='fill-white' />
      </RadixDropdownMenu.Content>
    </RadixDropdownMenu.Portal>
  );
});

export type DropdownMenuItemProps = RadixDropdownMenu.DropdownMenuItemProps;

const dropdownMenuItemClassName =
  'px-3 py-2 outline-none data-[highlighted]:bg-gray-100 rounded-md leading-none cursor-default';

export const DropdownMenuItem = forwardRef<
  HTMLDivElement,
  DropdownMenuItemProps
>((props, ref) => (
  <RadixDropdownMenu.Item
    {...props}
    ref={ref}
    className={dropdownMenuItemClassName}
  />
));

export type DropdownMenuCheckboxItemProps =
  RadixDropdownMenu.DropdownMenuCheckboxItemProps;

export const DropdownMenuCheckboxItem = forwardRef<
  HTMLDivElement,
  DropdownMenuCheckboxItemProps
>(({ children, ...props }, ref) => (
  <RadixDropdownMenu.CheckboxItem
    {...props}
    ref={ref}
    className={cx(dropdownMenuItemClassName, 'flex items-center gap-x-2')}>
    {children}
    <RadixDropdownMenu.ItemIndicator>
      <CheckIcon />
    </RadixDropdownMenu.ItemIndicator>
  </RadixDropdownMenu.CheckboxItem>
));

DropdownMenuContent.displayName = 'DropdownMenuContent';
DropdownMenuItem.displayName = 'DropdownMenuItem';
DropdownMenuCheckboxItem.displayName = 'DropdownMenuCheckboxItem';
