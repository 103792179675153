import { MediaObject, MediaObjectVariant } from './types';

export function getMediaObjectUrl(
  mediaObject: MediaObject,
  variant: MediaObjectVariant,
  { useAlternative = true } = {},
) {
  let types: (MediaObjectVariant | 'default')[] = [
    'extra_large_float',
    'large',
    'large_float',
    'medium',
    'medium_float',
    'default',
    'small',
    'extra_small',
    'thumbnail',
  ];
  const url = mediaObject.variantUrls[variant];
  if (url) {
    return url;
  }
  if (useAlternative) {
    const typeIndex = types.indexOf(variant);
    for (let i = typeIndex; i >= 0; --i) {
      const type = types[i];
      const url =
        type === 'default' ? mediaObject.url : mediaObject.variantUrls[type];
      if (url) {
        return url;
      }
    }
    for (let i = typeIndex; i < types.length; ++i) {
      const type = types[i];
      const url =
        type === 'default' ? mediaObject.url : mediaObject.variantUrls[type];
      if (url) {
        return url;
      }
    }
  }
}
