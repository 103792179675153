import { Locale } from '@afishauz/api/config';

export const AD_ZONES = {
  ru: {
    BRANDING: 23,
    FIXED_BUTTON: 91,
    DESKTOP_CONTENT: 4,
    DESKTOP_TOP: 81,
    DESKTOP_ASIDE: 5,
    DESKTOP_ASIDE_MINI: 42,
    MOBILE_STANDARD: 31,
    MOBILE_SPLASH: 67,
    MOBILE_SPLASH_EURO_2024: 116,
    MOBILE_STANDARD_EURO_2024: 118,
    NAVIGATION_AD: 102,
  },
  uz: {
    BRANDING: 94,
    FIXED_BUTTON: 99,
    DESKTOP_CONTENT: 98,
    DESKTOP_TOP: 97,
    DESKTOP_ASIDE: 95,
    DESKTOP_ASIDE_MINI: 96,
    MOBILE_STANDARD: 101,
    MOBILE_SPLASH: 100,
    MOBILE_SPLASH_EURO_2024: 117,
    MOBILE_STANDARD_EURO_2024: 118,
    NAVIGATION_AD: 103,
  },
};

export const SOCIAL_MEDIA = {
  ru: {
    TELEGRAM: 'https://t.me/afishauz',
    INSTAGRAM: 'https://www.instagram.com/afishauzb',
    FACEBOOK: 'https://www.facebook.com/afishauz',
    TWITTER: 'https://twitter.com/afishauz',
    YOUTUBE: 'https://www.youtube.com/channel/UCIl0n86Cj42JzayWIEo2uQA',
  },
  uz: {
    TELEGRAM: 'https://t.me/afishauzb',
    INSTAGRAM: 'https://www.instagram.com/afishauz',
    FACEBOOK: 'https://www.facebook.com/afishauzb',
    TWITTER: 'https://twitter.com/afishauz',
    YOUTUBE: 'https://www.youtube.com/channel/UCIl0n86Cj42JzayWIEo2uQA',
  },
};

export enum LOCALE {
  ru = 'ru',
  uz = 'uz',
}

export const parseLocale = (str: string = 'ru'): Locale => {
  return str === 'uz' ? 'uz' : 'ru';
};
