import useTranslation from 'next-translate/useTranslation';
import { Box } from '@afishauz/ui-kit/box';
import { List, ListItemMeta, ListItemTitle } from '@afishauz/ui-kit/list';
import {
  DelimitedList,
  DelimitedListItem,
} from '@afishauz/ui-kit/delimited-list';
import { LoadingButton } from '@afishauz/core/widgets/loading-button';
import { ListItem, ListTitle } from '@/layouts/header/search/list';
import { getVideoLink, getVideoFirstFrame } from '@afishauz/api/videos';
import { parseLocale } from '@afishauz/core/config';
import { useSearchVideos } from '@afishauz/core/pages/search';

interface Props {
  query: string;
  fuzzy?: boolean;
}

export const Videos = ({ query, fuzzy = false }: Props) => {
  const { t, lang } = useTranslation();
  const locale = parseLocale(lang);
  const { list, hasMoreItems, getNextPage, isValidating, totalItems } =
    useSearchVideos({ q: query, locale, fuzzy });

  if (totalItems === 0) {
    return null;
  }

  return (
    <Box component='section'>
      <ListTitle>{t('layouts.search.movies')}</ListTitle>
      <List>
        {list.map(video => (
          <ListItem
            link={{ href: getVideoLink(video) }}
            img={{
              src: getVideoFirstFrame(video, 'extra_small'),
              alt: video.title,
              size: 'sm',
            }}
            key={video['@id']}>
            <ListItemTitle title={video.title} size='sm'>
              {video.title}
            </ListItemTitle>
            <ListItemMeta>
              <DelimitedList>
                {(video.originalTitle || video.worldPremiereYear) && (
                  <DelimitedListItem>
                    {[video.originalTitle, video.worldPremiereYear]
                      .filter(Boolean)
                      .join(', ')}
                  </DelimitedListItem>
                )}
                {video.genres.length > 0 && (
                  <DelimitedListItem>{video.genres[0].name}</DelimitedListItem>
                )}
              </DelimitedList>
            </ListItemMeta>
          </ListItem>
        ))}
      </List>
      {hasMoreItems && (
        <LoadingButton
          loading={isValidating}
          onClick={getNextPage}
          disabled={isValidating}
          className='mt-3'
          size='sm'
        />
      )}
    </Box>
  );
};
